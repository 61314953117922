<template>
  <div class="content_vox">
    <div class="content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <el-form
            label-width="120px"
            :model="chainData"
            ref="chainRef"
            :rules="chainRule"
          >
            <div class="mainTop">
              <el-form-item
                label="行为名称："
                style="width: 470px"
                prop="labelName"
              >
                <el-input
                  v-model="chainData.labelName"
                  placeholder="请输入行为名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="上级行为：" style="width: 470px" prop="">
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  v-model="chainData.parentId"
                >
                  <el-option
                    v-for="item in sceneList"
                    :key="item.id"
                    :label="item.labelName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="关联产品：" style="width: 470px" prop="productType">
                <el-select
            
                  placeholder="请选择"
                  v-model="chainData.productType"
                >
                  <el-option
                   
                  
                    label="硬件类型"
                    value="硬件类型"
                  >
                  </el-option>
                  <el-option
                   
                  
                   label="软件类型"
                   value="软件类型"
                 >
                 </el-option>
                </el-select>
              </el-form-item> -->
<!-- 
              <el-form-item
                label="关联产品："
                style="width: 470px"
                prop="productCode"
              >
                <el-select
                  filterable
                  remote
                  multiple
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethods"
                  @change="$forceUpdate()"
                  v-model="chainData.productCodeList"
                >
                  <el-option
                    v-for="item in sceneArr"
                    :key="item.id"
                    :label="item.productName"
                    :value="item.productCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->

              <el-form-item label="描述：" prop="labelDescribe">
                <el-input
                  v-model="chainData.labelDescribe"
                  maxlength="100"
                  show-word-limit
                  style="width: 470px"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input>
                <!-- <Editorbar
                  v-model="chainData.sceneDescribe"
                  :isClear="isClear"
                  style="width: 50%"
                /> -->
              </el-form-item>
              <el-form-item label="价值：" prop="labelValue">
                <el-input
                  v-model="chainData.labelValue"
                  maxlength="100"
                  show-word-limit
                style="width: 470px"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input>
                <!-- <Editorbar
                  v-model="chainData.sceneSituation"
                  :isClear="isClear"
                  style="width: 50%"
                /> -->
              </el-form-item>

              <!-- <el-form-item label="技术方案：">
              <el-input
                v-model="chainData.technicalSolution"
                style="width: 80%"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
              ></el-input>
            </el-form-item> -->
            </div>
          </el-form>
          <!-- <div class="ess_people" v-if="personData">
            <div class="ess_people_list">
              创建人：{{ chainData.createName }}
            </div>
            <div class="ess_people_list">
              修改人：{{ chainData.updateName }}
            </div>
            <div class="ess_people_list">
              创建时间：{{ chainData.createTimes }}
            </div>
            <div class="ess_people_list">
              修改时间：{{ chainData.updateTimes }}
            </div>
          </div> -->

          
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bottom">
            <el-button type="primary" @click="saveItem">保存</el-button>
            <el-button @click="cancelItem">取消</el-button>
          </div>
  </div>
</template>
  
  <script>
import Editorbar from "@/components/Editorbar";
import {
  insertBehaviorLabel,
  selectBehaviorLabelById,
  updateBehaviorLabelById,
} from "@/api/Sceneknowledge.js";

import {
  selectAssociatedScenarios,
  selectAssociated,
  getProductByName,
} from "@/api/standardKnowledge.js";
export default {
  name: "addbehavioral",
  components: {
    Editorbar,
  },
  watch: {},
  created() {
    if (this.personData) {
      this.search();
      this.remoteMethods("");
    }
  },
  computed: {
    personData() {
      return Number(this.$route.query.id);
    },
  },
  data() {
    return {
      activeName: "first",
      sceneList: [], //
      sceneArr: [],

      isClear: null,
      isShow: false,
      isShowManufacturingCategory: false,
      options: [],
      chainData: {
        productCodeList: [],
        labelDescribe: "",
        labelName: "",
        labelValue: "",
        parentId: "",
        productCode: "",
        productType: "",
      },

      //企业行为

      chainRule: {
        labelName: [
          { required: true, message: "请填写行为名称", trigger: "blur" },
        ],
        productType: [
          { required: true, message: "请选择关联产品", trigger: "blur" },
        ],
        labelDescribe: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
        // labelValue: [
        //   { required: true, message: "请输入价值", trigger: "blur" },
        // ],
        productCodeList: [
          { required: true, message: "请输入选择产品名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    onchange(val){
      console.log(val);
      
    },  
    lookClick(val) {
      this.chainData.sceneImgUrl = val;
    },
    saveClick(val) {
      this.chainData.sceneImgList = val;
    },
    async remoteMethod(query) {
      const res = await selectAssociated({ sceneName: query });
      if (res.code == 200) {
        this.sceneList = res.data;
      }
    },
    async remoteMethods(query) {
      const info = {
        productName: query,
      };

      const res = await getProductByName(info);
      if (res.code == 200) {
        this.sceneArr = res.data;
      }
    },

    async search() {
      const res = await selectBehaviorLabelById({ id: this.personData });
      if (res.code == 200) {
        // Object.assign(this.chainData, res.data);
        this.chainData = res.data;
        this.remoteMethod(this.chainData.superiorLabelName);
        if( this.chainData.behaviorProductResultList){
          this.chainData.productCodeList = this.chainData.behaviorProductResultList.map((el) => {
          return el.productCode;
        });
        }
      
      
       
        // this.remoteMethods(this.chainData.productName)
      } else {
        this.$message.error("查询失败");
      }
    },
    saveItem() {
      // console.log(this.radiolist);
      let res;
      this.$refs.chainRef.validate(async (valid) => {
        if (valid) {
          if (!this.personData) {
            res = await insertBehaviorLabel(this.chainData);
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            res = await updateBehaviorLabelById(this.chainData);
            if (res.code === 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            }
          }
        }
      });
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
};
</script>
  
  <style lang="less" scoped>
.el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
svg {
  font-size: 14px;
}
.mainTop {
  background-color: white;
  padding: 20px 20px 0 20px;
  // margin-bottom: 20px;
}
.circle_flex {
  width: 680px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.content_vox {
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
  height: calc(100 - 120px);
}
.content {
  overflow: auto;
  width: 100%;
  height: calc(100vh - 240px);
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: grey; /*滚动条的背景颜色*/
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}
/deep/.el-input {
  width: 470px !important;
}
// /deep/.el-textarea {
//   width: 80% !important;
//   // min-height: 40px !important;
// }
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
.bottomStyle {
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
  /deep/ .el-upload--picture-card {
    width: 150px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 150px;
    height: 100px;
    // line-height: 150px;
  }
  /deep/ .el-upload {
    background-color: #fff;
  }
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.flexStyle {
  display: flex;
  margin: 10px 10px 10px 0;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-tag.el-tag--info {
  background: rgba(30, 159, 255, 0.2);
  color: rgb(30, 159, 255);
}
/deep/ .el-tag.el-tag--info .el-tag__close {
  color: rgb(30, 159, 255);
  background: rgba(30, 159, 255, 0.2);
}
</style>
  